export const createSupportFormLink = (
  email?: string,
  userId?: string,
  locale?: string,
  version?: string,
) => {
  const l = locale === "sv" ? "sv" : "en-gb";
  const url = new URL(`https://matchiplayers.zendesk.com/hc/${l}/requests/new`);

  // Params supported by the Zendesk form.
  // What params that are available are controlled in the Zendesk dashboard.
  const params = {
    // Email
    tf_anonymous_requester_email: email,
    // Device
    tf_25675771719325: navigator?.userAgent ?? "Unknown",
    // User ID
    tf_25675740810525: userId,
    // App version
    tf_25675712177949: version,
  };

  Object.entries(params).forEach(([key, value]) => {
    if (value) url.searchParams.append(key, value);
  });

  return url.toString();
};
