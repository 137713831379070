import { Suspense, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import {
  setUserId,
  identify,
  Identify,
  track,
} from "@amplitude/analytics-browser";
import { setUser } from "@sentry/react";
import to from "await-to-js";
import {
  Await,
  useLoaderData,
  useSubmit,
  ActionFunctionArgs,
  redirect,
  LoaderFunctionArgs,
  defer,
  useNavigate,
  useParams,
  useRevalidator,
} from "react-router-dom";
import {
  ApiError,
  AuthorizedService,
  checkoutResponse,
  getCheckoutQuery,
  getCheckoutQueryKey,
} from "matchi-api";
import { usePaymentLoadingStore } from "@/paymentLoadingStore";
import { queryClient } from "@/queryClient";
import { usePaymentServiceEvents } from "@/hooks";
import { getTokenOrLogin } from "@/utils/auth";
import { init, useT, keycloakToTransifexLocale, t } from "i18n";
import {
  toast,
  OrderSummary,
  ApplyOffers,
  PaymentForm,
  ErrorBoundary,
} from "@/components";
import {
  PaymentServiceOption,
  InternalPaymentOption,
  MixedPaymentOption,
  getPaymentOptionsQueryKey,
  getPaymentServiceQuery,
  getPaymentOptionsQuery,
} from "@/queries";
import {
  poll,
  getQueryParam,
  isOrderCancelled,
  isOrderProcessed,
  isOrderCompleted,
} from "@/utils";
import type { PaymentMethod } from "@adyen/adyen-web/dist/types/types";
import { ERROR_MESSAGES } from "@/utils/errors";

interface LoaderData {
  checkout: checkoutResponse;
  paymentOptions: MixedPaymentOption[];
  isRedirect?: boolean;
  redirectUrl?: string;
}

const REVALIDATE_EVENT = "REVALIDATE_EVENT";

const Page = () => {
  const submit = useSubmit();
  const { data } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const navigate = useNavigate();
  const { token } = useParams();
  const t = useT();
  const [error, setError] = useState<string>();
  const { revalidate } = useRevalidator();

  useEffect(() => {
    const handler = () => {
      revalidate();
    };

    document.addEventListener(REVALIDATE_EVENT, handler);

    return () => {
      document.removeEventListener(REVALIDATE_EVENT, handler);
    };
  }, [revalidate]);

  usePaymentServiceEvents({
    onPaymentCompleted: async e => {
      const { data } = e.detail;

      // Handle error result codes
      // @see https://docs.adyen.com/online-payments/payment-result-codes/
      // @see https://docs.adyen.com/development-resources/testing/result-code-testing/adyen-response-codes/
      switch (data.resultCode) {
        case "Error":
          usePaymentLoadingStore.setState({
            paymentFormSubmitting: false,
            verifyingRedirectResult: false,
          });
          toast({
            title: t("Failed to process payment"),
            description: t("Please try again later."),
          });
          track("Adyen error for payment");
          return;

        case "Refused":
          usePaymentLoadingStore.setState({
            paymentFormSubmitting: false,
            verifyingRedirectResult: false,
          });
          toast({
            title: t("Your payment was refused"),
            description: t(
              "To continue, please try again using another payment method.",
            ),
          });
          track("Adyen refused payment");
          return;

        case "Cancelled":
          usePaymentLoadingStore.setState({
            paymentFormSubmitting: false,
            verifyingRedirectResult: false,
          });
          toast({
            title: t("Payment cancelled"),
            description: t("Please try again later."),
          });
          track("Adyen cancelled payment");
          return;
      }

      track("Payment completed");

      let [err, checkout] = await to(
        poll(
          () => AuthorizedService.getCheckout(token!),
          checkout => !isOrderProcessed(checkout),
          2000,
        ),
      );

      if (err || !checkout) {
        usePaymentLoadingStore.setState({
          paymentFormSubmitting: false,
          verifyingRedirectResult: false,
        });
        setError(t("Could not confirm order"));
        track("Could not confirm the order");
        return;
      }

      if (isOrderCancelled(checkout)) {
        setError(t("Could not confirm order"));
        usePaymentLoadingStore.setState({
          paymentFormSubmitting: false,
          verifyingRedirectResult: false,
        });
        track("Order was cancelled after completed payment");
        return;
      }

      // MATCHi has confirmed the order and the payment is completed
      track("Order confirmed");

      const onSuccessUrl = getQueryParam("onSuccessUrl");
      const autoRedirect = getQueryParam("autoRedirect");

      if (onSuccessUrl) {
        return autoRedirect === "false"
          ? navigate(`/pay/${token}/success?onSuccessUrl=${onSuccessUrl}`)
          : (window.location.href = onSuccessUrl);
      }

      return navigate(`/pay/${token}/success`);
    },
    onError: e => {
      const { error } = e.detail;

      // User cancelling payments triggers this callback.
      // We dont want to show an error message in that case.
      if (error.name !== "CANCEL") {
        toast({
          title: t("Failed to process payment"),
          description: t("Please try again later."),
        });
      }

      usePaymentLoadingStore.setState({
        paymentFormSubmitting: false,
        verifyingRedirectResult: false,
      });

      track("Adyen onError callback triggered", { name: error.name });
    },
  });

  return (
    <Suspense>
      <Await resolve={data}>
        {({
          isRedirect,
          redirectUrl,
          checkout,
          paymentOptions,
        }: LoaderData) => {
          if (error) return <ErrorBoundary errorMessage={error} />;
          if (isRedirect) {
            window.location.href = redirectUrl!;
            return <></>;
          }

          return (
            <div className="flex flex-col space-y-10">
              <OrderSummary checkout={checkout} />
              <ApplyOffers checkout={checkout} />
              <PaymentForm
                checkout={checkout}
                paymentOptions={paymentOptions}
                onSubmit={({ paymentOptionId }) => {
                  const formData = new FormData();
                  formData.append("paymentOptionId", paymentOptionId);
                  submit(formData, { method: "post" });
                }}
              />
            </div>
          );
        }}
      </Await>
    </Suspense>
  );
};

const loader = async ({ params: { token } }: LoaderFunctionArgs) => {
  // We need to return a promise from the loader or Suspense will not wait for the data to be fetched.
  // @see https://reactrouter.com/en/main/guides/deferred#using-defer
  const fetchCheckoutData = async () => {
    if (!token) {
      track("No token found");
      throw new Error("No token found");
    }

    usePaymentLoadingStore.setState({ isLoading: true });

    // Get bearer token from keycloak
    const bearer = await getTokenOrLogin();
    const { locale: userLocale, sub: keycloakId } =
      jwtDecode<KeycloakJWTPayload>(bearer);

    // Identify users in Amplitude
    setUserId(keycloakId);
    const identifyEvent = new Identify();
    identifyEvent.set("checkoutToken", token);
    identify(identifyEvent);

    // .. and Sentry
    setUser({ id: keycloakId });

    // Initialize Transifex
    const locale = keycloakToTransifexLocale(userLocale);
    await init({ token: import.meta.env.VITE_TRANSIFEX_TOKEN, locale });

    let err: ApiError | null;
    let checkout, paymentService, paymentOptions;

    [err, checkout] = await to(
      queryClient.ensureQueryData(getCheckoutQuery(token)),
    );

    if (err?.body.code === "E2600") {
      throw new Error(ERROR_MESSAGES.INVALID_USER);
    }

    if (err || !checkout) throw new Error("Could not fetch checkout data");

    // If order is already processed, return the checkout data.
    // It will be handled in the then() callback
    if (isOrderProcessed(checkout)) return { checkout };

    [err, paymentService] = await to(
      queryClient.ensureQueryData(getPaymentServiceQuery(token)),
    );

    if (err || !paymentService)
      throw new Error("Could not initiate payment service");

    const { coupons } = checkout.paymentMethods;

    // Prepare payment options
    [err, paymentOptions] = await to(
      queryClient.ensureQueryData(
        getPaymentOptionsQuery(coupons, paymentService),
      ),
    );

    if (err || !paymentOptions) throw new Error("No payment methods found");

    if (checkout.adyenGiftCardOutcomes?.length) {
      const withoutCoupons = paymentOptions.filter(
        option => option.type !== "COUPON",
      );

      return { checkout, paymentOptions: withoutCoupons, paymentService };
    }

    return { checkout, paymentOptions, paymentService };
  };

  const data = fetchCheckoutData()
    .then(data => {
      // Handle orders that are completed
      if (isOrderCompleted(data.checkout)) {
        const onSuccessUrl = getQueryParam("onSuccessUrl");
        const autoRedirect = getQueryParam("autoRedirect");
        const redirectUrl = onSuccessUrl
          ? autoRedirect === "false"
            ? `/pay/${token}/success?onSuccessUrl=${onSuccessUrl}`
            : onSuccessUrl
          : `/pay/${token}/success`;

        return { isRedirect: true, redirectUrl };
      }

      // Handle orders that are cancelled
      if (isOrderCancelled(data.checkout))
        throw new Error("Order was cancelled before completed payment");

      // Throw error if no payment methods are found
      if (!data.paymentService || !data.paymentOptions)
        throw new Error("No payment methods found");

      // Handle redirectResults
      // Some payment providers, like iDEAL, will redirect the user to a third party page to confirm the payment.
      // When the user is redirected back, the "redirectResult" query param will be added to the url.
      // We call the 'submitDetails' method and the rest is handled as any other payment in the 'onPaymentCompleted' callback.
      const redirectResult = getQueryParam("redirectResult");
      if (redirectResult) {
        usePaymentLoadingStore.setState({ verifyingRedirectResult: true });
        data.paymentService.submitDetails({ details: { redirectResult } });
      } else {
        usePaymentLoadingStore.setState({ isLoading: false });
      }

      return data;
    })
    .catch(error => {
      usePaymentLoadingStore.setState({
        isLoading: false,
        verifyingRedirectResult: false,
      });
      track(error.message);
      throw error;
    });

  return defer({ data });
};

const action = async ({ request, params: { token } }: ActionFunctionArgs) => {
  usePaymentLoadingStore.setState({ paymentFormSubmitting: true });
  track("Submitted payment form");

  if (!token) {
    usePaymentLoadingStore.setState({ paymentFormSubmitting: false });
    track("No token found");
    throw new Error("No token found");
  }

  const formData = await request.formData();
  const paymentOptionId = String(formData.get("paymentOptionId"));

  let err = null;

  // Handle free orders
  if (paymentOptionId === "FREE") {
    [err] = await to(
      AuthorizedService.createCheckoutBooking(token, {
        payment: { method: "FREE" },
      }),
    );

    if (err) {
      usePaymentLoadingStore.setState({ paymentFormSubmitting: false });
      toast({
        title: t("Could not confirm order"),
        description: t("Please try again later."),
      });
      track("Confirming free order failed");
      return null;
    }

    // Reset query to make sure user can't go back and submit the form again
    queryClient.resetQueries({ queryKey: getCheckoutQueryKey(token) });

    track("Payment completed");
  } else {
    // Get selected payment option
    const paymentOption = queryClient
      .getQueryData<MixedPaymentOption[]>(getPaymentOptionsQueryKey)
      ?.find(({ id }) => id === paymentOptionId);

    if (!paymentOption) {
      usePaymentLoadingStore.setState({ paymentFormSubmitting: false });
      track("No payment methods found");
      throw new Error("No payment methods found");
    }

    // Handle Adyen payment option
    if (paymentOption.type === "PAYMENT_SERVICE") {
      const { component, method } = paymentOption as PaymentServiceOption;

      // TODO: "component.isValid" is false for Google Pay, so we avoid checking for that payment method.
      // @see https://github.com/Adyen/adyen-web/issues/2421
      if (method.type !== "googlepay" && !component.isValid) {
        usePaymentLoadingStore.setState({ paymentFormSubmitting: false });
        component.showValidation();
        track("Submitted invalid payment form");
        return null;
      }

      // If payment method is adyen gift card, hijack the flow to call balanceCheck.
      // @see https://docs.adyen.com/payment-methods/gift-cards/web-component/?tab=config-sessions_2
      if (method.type === "giftcard") {
        // Cache the previous gift card outcome to check against when polling in the callback
        let prevCheckout;
        let checkoutData;
        [err, prevCheckout] = await to(AuthorizedService.getCheckout(token));

        if (err || !prevCheckout) {
          toast({
            title: t("Could not redeem gift card"),
            description: t(
              "Please try again later. If the problem persists, please contact support.",
            ),
          });
          return null;
        }

        const prev = prevCheckout?.adyenGiftCardOutcomes?.length ?? 0;

        // This callback will be triggered when the giftcard doesn't cover the full amount.
        component.props.onOrderCreated = async (orderStatus: any) => {
          [err] = await to(
            poll(
              () => AuthorizedService.getCheckout(token),
              checkout => {
                // Poll until the "adyenGiftCardOutcomes" array is larger than it was before the giftcard was applied.
                // This means the giftcard has been applied and the checkout session should be up to date.
                const next = checkout.adyenGiftCardOutcomes?.length ?? 0;
                return next <= prev;
              },
              2000,
            ),
          );

          if (err) {
            toast({
              title: t("Could not redeem gift card"),
              description: t(
                "Please try again later. If the problem persists, please contact support.",
              ),
            });
            track("Polling for applied gift card failed");
            return null;
          }

          // Fetch the updated checkout data
          [err, checkoutData] = await to(AuthorizedService.getCheckout(token));

          if (err) {
            toast({
              title: t("Could not redeem gift card"),
              description: t(
                "Please try again later. If the problem persists, please contact support.",
              ),
            });
            track("Fetching checkout after gift card was applied failed");
            return null;
          }

          // Update the cache
          queryClient.setQueryData(getCheckoutQueryKey(token), checkoutData);

          // Get the payment service and..
          const paymentService = await queryClient.ensureQueryData(
            getPaymentServiceQuery(token),
          );

          // Recreate the components with the updated paymentService.
          // At this point, the gift card has been applied and the components needs to be recreated in order for them to reflect the updated state
          await queryClient.fetchQuery(
            getPaymentOptionsQuery(
              checkoutData?.paymentMethods.coupons,
              paymentService,
            ),
          );

          // Unset the loading state and send event to revalidate the loader, making sure the DOM is rerendered.
          usePaymentLoadingStore.setState({ paymentFormSubmitting: false });
          const event = new CustomEvent(REVALIDATE_EVENT);
          document.dispatchEvent(event);

          return null;
        };

        // This callback will be triggered when the giftcard covers the full amount.
        component.props.onRequiringConfirmation = () => {
          component.submit();
        };

        // "balanceCheck" method is not typed in adyens library but it does exist on the giftcard component
        // @ts-ignore
        component.balanceCheck();
        return null;
      }

      // Submit the payment form. If it's valid, the 'onPaymentCompleted' callback
      // will fire and the rest of the payment flow is handled from there.
      component.submit();
      return null;
    }

    // Handle coupon (value card) payment option
    if (paymentOption.type === "COUPON") {
      const internalOption = paymentOption as InternalPaymentOption;

      [err] = await to(
        AuthorizedService.createCheckoutBooking(token, {
          payment: {
            method: internalOption.method.method!,
            id: internalOption.method.id,
          },
        }),
      );

      if (err) {
        usePaymentLoadingStore.setState({ paymentFormSubmitting: false });
        toast({
          title: t("Payment failed"),
          description: t("Please try again later."),
        });
        track("Coupon payment failed");
        return null;
      }

      // Reset query to make sure user can't go back and submit the form again
      queryClient.resetQueries({ queryKey: getCheckoutQueryKey(token) });

      track("Payment completed");
    }
  }

  // Wait for the order to be confirmed
  [err] = await to(
    poll(
      () => AuthorizedService.getCheckout(token),
      checkout => !isOrderProcessed(checkout),
      2000,
    ),
  );

  if (err) {
    usePaymentLoadingStore.setState({ paymentFormSubmitting: false });
    track("Could not confirm the order");
    throw new Error("Could not confirm the order.");
  }

  // Order is confirmed for free the free booking
  track("Order confirmed");

  // Redirect the user to the success page or the onSuccessUrl
  const onSuccessUrl = getQueryParam("onSuccessUrl");
  const autoRedirect = getQueryParam("autoRedirect");
  const redirectUrl = onSuccessUrl
    ? autoRedirect === "false"
      ? `/pay/${token}/success?onSuccessUrl=${onSuccessUrl}`
      : onSuccessUrl
    : `/pay/${token}/success`;

  return redirect(redirectUrl);
};

export const Payment = { Page, loader, action };
