import { T } from "i18n";
import { useRouteError } from "react-router-dom";
import { Button, Link } from "ui";
import { auth } from "matchi-auth";
import { ERROR_MESSAGES } from "@/utils/errors";
import { createSupportFormLink } from "@/utils/createSupportFormLink";
import * as pkg from "@/../package.json";

type RouteError = Error & {
  statusText?: string;
  status?: number;
};

interface ErrorBoundaryProps {
  errorMessage?: string;
}

export const ErrorBoundary = ({ errorMessage }: ErrorBoundaryProps) => {
  const error = useRouteError() as RouteError;
  const message = errorMessage ?? error?.message ?? "Unknown error occurred";
  const token = auth.getIdTokenParsed();
  const url = window?.location?.origin + window?.location?.pathname;

  const supportFormLink = createSupportFormLink(
    token?.email,
    token?.sub,
    token?.locale,
    url,
  );

  if (message === ERROR_MESSAGES.LOCAL_STORAGE_DISABLED) {
    return (
      <ErrorMessage
        title={<T _str="Cookies are required to complete the payment" />}
        description={
          <T
            _str="Cookies seems to be blocked by your browser. Please try enabling cookies in your browser settings and refresh the page. If the issue persists, {supportCta}."
            supportCta={
              <span className="inline">
                <Link href={supportFormLink} target="_blank">
                  <T _str="contact our support team" />
                </Link>
              </span>
            }
          />
        }
        message={message}
      />
    );
  }

  if (message === ERROR_MESSAGES.INVALID_USER) {
    return (
      <ErrorMessage
        title={<T _str="We were unable to process your order" />}
        description={
          <T
            _str="Please make sure that you are signed in to the correct user account. If the problem persists, {supportCta}."
            supportCta={
              <span className="inline">
                <Link href={supportFormLink} target="_blank">
                  <T _str="contact our support team" />
                </Link>
              </span>
            }
          />
        }
        message={message}
        action={
          <Button onClick={auth.logout} block>
            <T _str="Logout" />
          </Button>
        }
      />
    );
  }

  return (
    <ErrorMessage
      title={<T _str="Something went wrong" />}
      description={
        <T
          _str="Do you have questions or need help? {supportCta}."
          supportCta={
            <span className="inline">
              <Link href={supportFormLink} target="_blank">
                <T _str="Reach out to our support team" />
              </Link>
            </span>
          }
        />
      }
      message={message}
    />
  );
};

interface ErrorMessageProps {
  title: React.ReactNode;
  description: React.ReactNode;
  message: string;
  action?: React.ReactNode;
}

const ErrorMessage = ({
  title,
  description,
  message,
  action,
}: ErrorMessageProps) => {
  const token = auth.getIdTokenParsed();
  const path = window?.location?.href
    ? new URL(window.location.href)?.pathname
    : null;

  return (
    <div>
      <h1 className="mb-2 text-lg font-medium">{title}</h1>
      <div className="border-default mb-4 mt-4 rounded-xl border p-4">
        <p className="text-gray-1100 font-medium">{description}</p>
      </div>
      <div className="mb-3 flex flex-col rounded-lg bg-gray-300 px-4 py-3 text-xs">
        {token?.email && <code>user: {token.email}</code>}
        {path && <code>path: {path}</code>}
        {message && <code>error: {message}</code>}
      </div>
      {action}
    </div>
  );
};
